import React from 'react';
import {
  Added,
  Improved,
  Fixed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="September 2021"
      subnav="release-notes">
      <div id="September2021" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          The long, sunny days of summer are behind us, but the future of
          accessibility at Hudl just got a little brighter! This month's updates
          are primarily focused on accessibility improvements for our components
          so we can get closer to our goal of{' '}
          <Link href="https://www.a11yproject.com/checklist/">WCAG 2.1 AA</Link>{' '}
          compliance.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.10.1 - 4.12.4"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases"
            />
            <div className={styles.changesList}>
              <Added>
                <code>title</code> and <code>aria-describedby</code> properties
                to{' '}
                <Link href="/components/data-visualization/data-table/code">
                  DataTable
                </Link>{' '}
                component.
              </Added>
              <Improved>
                Accessibility for{' '}
                <Link href="/components/icons/code">Icons</Link> and{' '}
                <Link href="/components/forms/text-area/design/#Help%20Text">
                  Help Text
                </Link>
                .
              </Improved>
              <Improved>
                Accessibility for users who{' '}
                {/* eslint-disable-next-line max-len */}
                <Link href="https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion">
                  prefer reduced motion
                </Link>
                .
              </Improved>
              <Improved>
                Accessibility for{' '}
                <Link href="/components/link/design/#External%20Links">
                  Links
                </Link>{' '}
                when directed to external URLs.
              </Improved>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="2.1.0"
              versionUrl="https://github.com/hudl/jarvis/releases/tag/v2.1.0"
            />

            <div className={styles.changesList}>
              <Fixed>
                Text wrapping issue with{' '}
                <Link href="/components/avatars/code#react-native">
                  Avatars
                </Link>
                .
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                Warning note to the top of the{' '}
                <Link href="http://uniform.hudltools.com">
                  V3 documentation site
                </Link>
                .
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
